import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import personalInfoImg from './assets/personal.webp'; // Thêm tấm ảnh "Thông tin cá nhân"
import skillsImg from './assets/skills.webp'; // Thêm tấm ảnh "Khả năng nổi bật"
import activityImg from './assets/department.webp'; // Thêm tấm ảnh "Lĩnh vực hoạt động mong muốn"
import './index.css';
import CustomAlert from './CustomAlert';
const CandidateInformation = () => {
    const fields = useMemo(() => [
        { name: 'name', type: 'text', label: 'Họ và tên' },
        { name: 'classId', type: 'text', label: 'Mã lớp' },
        { name: 'major',  type: 'select', label: 'Chuyên ngành', options: ['Hệ thống thông tin quản lý', 'Hệ thống thông tin quản lý (hợp tác với Doanh nghiệp)', 'Thương mại điện tử', 'Thương mại điện tử (đào tạo bằng tiếng Anh)', 'Kinh doanh số và trí tuệ nhân tạo'] },
        { name: 'studentId', type: 'text', label: 'Mã số sinh viên' },
        { name: 'gender', type: 'radio', label: 'Giới tính', options:['Nam', 'Nữ']},
        { name: 'dayofBirth', type: 'date', label: 'Ngày sinh' },
        { name: 'hometown', type: 'text', label: 'Quê quán' },
        { name: 'email', type: 'email', label: 'Email (vui lòng điền email sinh viên)' },
        { name: 'linkFb', type: 'url', label: 'Link Facebook' },
        { name: 'address', type: 'text', label: 'Địa chỉ hiện tại' },
        { name: 'phone', type: 'tel', label: 'Số điện thoại' },
        { name: 'photo', type: 'url', label: 'Link drive ảnh (vui lòng mở quyền truy cập)' },
        { name: 'potential', type: 'checkbox_group', label: 'Chọn khả năng nổi bật của bạn (có thể chọn nhiều hơn 1 mục)', options: ['Lãnh đạo', 'Tổ chức', 'Lập trình', 'Viết lách', 'Nghiên cứu', 'Làm đồ thủ công', 'Thiết kế', 'Tiếng Anh', 'Chụp ảnh','Thuyết trình'] },
        { name: 'otherSkill', type: 'text', label: 'Khác (nếu có)' },
        { name: 'department_json', type: 'checkbox_group', label: 'Chọn ban mà bạn muốn hoạt động', options: ['Học Thuật','Truyền Thông','Nhân Sự','Sự Kiện'] },
    ], []);
    const [candidateInfo, setCandidateInfo] = useState(() => {
        const saved = sessionStorage.getItem('candidateInfo');
        return saved ? JSON.parse(saved) : {};
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [photoError, setPhotoError] = useState('');
    const [facebookError, setFacebookError] = useState('');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session-id');
    const navigate = useNavigate();

    useEffect(() => {
        const formCompleted = sessionStorage.getItem('formCompleted');
        if (!sessionId || formCompleted) {
            // Nếu không có sessionId, điều hướng về trang login
            navigate(formCompleted ? '/completion' : '/');
            console.error('Try to reconnect to previous page');
        }
        const storedData = sessionStorage.getItem('candidateInfo');
        if (storedData) {
            setCandidateInfo(JSON.parse(storedData));
        } else {
            const initialData = fields.reduce((acc, field) => {
                if (field.type === 'checkbox_group') {
                    acc[field.name] = [];
                } else {
                    acc[field.name] = '';
                }
                return acc;
            }, {});
            setCandidateInfo(prevInfo => ({ ...initialData, ...prevInfo }));
        }
    }, [fields, sessionId, navigate]);

    const isGoogleDriveUrl = (url) => {
        // Kiểm tra URL có phải là link Google Drive hợp lệ hay không
        const googleDriveUrlPattern = /^(https?:\/\/)?(www\.)?(drive|docs)\.google\.com\/.+$/;
        return googleDriveUrlPattern.test(url);
    };

    const isFacebookUrl = (url) => {
        // Kiểm tra URL có phải là link Facebook hợp lệ hay không
        const facebookUrlPattern = /^(https?:\/\/)?(www\.)?(facebook|fb)\.com\/.+$/;
        return facebookUrlPattern.test(url);
    };

    
    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'photo') {
            if (!isGoogleDriveUrl(value)) {
                setPhotoError('Vui lòng nhập một URL hợp lệ từ Google Drive.');
            } else {
                setPhotoError('');
            }
        }

        if (name === 'linkFb') {
            if (!isFacebookUrl(value)) {
                setFacebookError('Vui lòng nhập một URL hợp lệ từ Facebook.');
            } else {
                setFacebookError('');
            }
        }

        setCandidateInfo(prevInfo => {
            const updatedInfo = {
                ...prevInfo,
                [name]: value
            };
            sessionStorage.setItem('candidateInfo', JSON.stringify(updatedInfo)); // Lưu dữ liệu vào sessionStorage mỗi khi có thay đổi
            return updatedInfo;
        });
    };

    const handleCheckboxChange = (event, fieldName) => {
        const { value, checked } = event.target;
        
        setCandidateInfo(prevInfo => {
            const newValues = checked
                ? [...prevInfo[fieldName], value] // Thêm label nếu được chọn
                : prevInfo[fieldName].filter(item => item !== value); // Xoá label nếu bị deselect
    
            const updatedInfo = { ...prevInfo, [fieldName]: newValues };
            sessionStorage.setItem('candidateInfo', JSON.stringify(updatedInfo)); // Lưu dữ liệu vào sessionStorage mỗi khi có thay đổi
            return updatedInfo;
        });
    };
    
    
    const validateEmail = async (email) => {
        const response = await fetch('https://ctvapi.itbstclub.com/api/validate_email/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            return true;
        } else {
            const data = await response.json();
            setErrorMessage(data.error || 'Vui lòng điền email sinh viên');
            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        let updatedPotential = candidateInfo.potential;
    
        // Add otherSkill to potential if it exists and is not already included
        if (candidateInfo.otherSkill && !updatedPotential.includes(candidateInfo.otherSkill)) {
            updatedPotential = [...updatedPotential, candidateInfo.otherSkill];
        }
    
        // Field validations
        const maxLength = 255;
    
        // Validate 'name'
        if (!candidateInfo.name.trim()) {
            setErrorMessage("Họ và tên không được để trống.");
            return;
        }
        if (candidateInfo.name.length > maxLength) {
            setErrorMessage(`Họ và tên không được vượt quá ${maxLength} ký tự.`);
            return;
        }
    
        // Validate 'classId'
        if (!candidateInfo.classId.trim()) {
            setErrorMessage("Mã lớp không được để trống.");
            return;
        }
        if (candidateInfo.classId.length > maxLength) {
            setErrorMessage(`Mã lớp không được vượt quá ${maxLength} ký tự.`);
            return;
        }
    
        // Validate 'major'
        if (!fields[2].options.includes(candidateInfo.major)) {
            setErrorMessage("Vui lòng chọn chuyên ngành hợp lệ.");
            return;
        }
        if (candidateInfo.major.length > maxLength) {
            setErrorMessage(`Chuyên ngành không được vượt quá ${maxLength} ký tự.`);
            return;
        }
    
        // Validate 'studentId'
        if (!candidateInfo.studentId.trim()) {
            setErrorMessage("Mã số sinh viên không được để trống.");
            return;
        }
        if (candidateInfo.studentId.length > maxLength) {
            setErrorMessage(`Mã số sinh viên không được vượt quá ${maxLength} ký tự.`);
            return;
        }
    
        // Validate 'gender'
        if (!['Nam', 'Nữ'].includes(candidateInfo.gender)) {
            setErrorMessage("Vui lòng chọn giới tính hợp lệ.");
            return;
        }
        if (candidateInfo.gender.length > maxLength) {
            setErrorMessage(`Giới tính không được vượt quá ${maxLength} ký tự.`);
            return;
        }
    
        // Validate 'dayofBirth'
        const today = new Date();
        const birthDate = new Date(candidateInfo.dayofBirth);
        const age = today.getFullYear() - birthDate.getFullYear();
        if (isNaN(birthDate.getTime()) || age < 16 || age > 30) {
            setErrorMessage("Ngày sinh không hợp lệ hoặc bạn không nằm trong độ tuổi cho phép (16-30).");
            return;
        }
    
        // Validate 'hometown'
        if (!candidateInfo.hometown.trim()) {
            setErrorMessage("Quê quán không được để trống.");
            return;
        }
        if (candidateInfo.hometown.length > maxLength) {
            setErrorMessage(`Quê quán không được vượt quá ${maxLength} ký tự.`);
            return;
        }
    
        // Validate 'email'
        const isEmailValid = await validateEmail(candidateInfo.email);
        if (!isEmailValid) {
            return; // Stop submission if email is invalid
        }
    
        // Validate 'linkFb'
        if (candidateInfo.linkFb && !isFacebookUrl(candidateInfo.linkFb)) {
            setErrorMessage("Vui lòng nhập một URL hợp lệ từ Facebook.");
            return;
        }
    
        // Validate 'address'
        if (!candidateInfo.address.trim()) {
            setErrorMessage("Địa chỉ không được để trống.");
            return;
        }
        if (candidateInfo.address.length > maxLength) {
            setErrorMessage(`Địa chỉ không được vượt quá ${maxLength} ký tự.`);
            return;
        }
    
        // Validate 'phone'
        const phonePattern = /^[0-9]{10,15}$/;
        if (!phonePattern.test(candidateInfo.phone)) {
            setErrorMessage("Số điện thoại không hợp lệ. Vui lòng nhập số điện thoại từ 10 đến 15 chữ số.");
            return;
        }
        if (candidateInfo.phone.length > maxLength) {
            setErrorMessage(`Số điện thoại không được vượt quá ${maxLength} ký tự.`);
            return;
        }
    
        // Validate 'photo'
        if (candidateInfo.photo && !isGoogleDriveUrl(candidateInfo.photo)) {
            setErrorMessage("Vui lòng nhập một URL hợp lệ từ Google Drive.");
            return;
        }
    
        // Validate 'potential'
        if (updatedPotential.length === 0) {
            setErrorMessage("Vui lòng chọn ít nhất một khả năng nổi bật hoặc điền vào ô 'Khác' trước khi tiếp tục.");
            return;
        }
    
        // Validate 'department_json'
        if (candidateInfo.department_json.length === 0) {
            setErrorMessage("Vui lòng chọn ít nhất một lĩnh vực hoạt động mong muốn trước khi tiếp tục.");
            return;
        }
    
        // If all validations pass, save the candidate information and proceed to the next page
        sessionStorage.setItem('candidateInfo', JSON.stringify({
            ...candidateInfo,
            potential: updatedPotential,
        }));
    
        navigate(`/question_page?session-id=${sessionId}`);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/');
    };

    return (
        <form  class="d-flex align-items-end flex-column" onSubmit={handleSubmit}>
            {/* Phần 1: Thông tin cá nhân */}
            <div class="container">
                <img src={personalInfoImg} alt="Thông tin cá nhân" class="sm-3"style ={{marginTop: '5px',marginBottom: '5px', width: '320px'}}/>
                <div class="container d-flex flex-row justify-content-between">
                    {/* Bên trái */}
                    <div style={{ width: '45%' }}>
                            {fields.slice(0, 5).map(field => (
                            <div key={field.name} style={{ marginBottom: '12px' }}>
                                <label style={{
                                    fontSize: '15px',
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                    marginBottom: '5px',
                                    display: 'block'
                                }}>
                                    {field.label}
                                </label>
                                {field.type === 'radio' ? (
                                    field.options.map(option => (
                                        <div key={option} style={{display: 'inline-block', marginBottom: '14px',}}>
                                            <label style={{ marginRight: '20px', display: 'inline-flex', alignItems: 'center', color: '#ffffff'}}>
                                                <input
                                                    type="radio"
                                                    name={field.name}
                                                    value={option}
                                                    checked={candidateInfo[field.name] === option}
                                                    onChange={handleChange}
                                                    required
                                                    style={{
                                                        marginRight: '8px',
                                                        display: 'inline-block',
                                                        color: '#ffffff',
                                                        
                                                    }}
                                                />
                                                {option}
                                            </label>
                                        </div>
                                    ))
                                 ) : field.type === 'select' ? (
                        <select
                            name={field.name}
                            value={candidateInfo[field.name] || ''}
                            onChange={handleChange}
                            required
                            style={{
                                padding: '8px',
                                fontSize: '14px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                width: '70%'
                            }}>
                            <option value="" disabled>Chọn {field.label}</option>
                            {field.options.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                                ) : (
                                    <input
                                        type={field.type}
                                        name={field.name}
                                        value={candidateInfo[field.name] || ''}
                                        onChange={handleChange}
                                        required
                                        autoComplete="new-password"
                                        style={{
                                            padding: '8px',
                                            fontSize: '14px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            width: '70%'
                                        }} />
                                )}
                            </div>
                        ))}
                        <div style={{ marginBottom: '12px' }}>
                            <label style={{
                                fontSize: '15px',
                                color: '#ffffff',
                                fontWeight: 'bold',
                                marginBottom: '5px',
                                display: 'block'
                            }}>
                                Ngày sinh
                            </label>
                            <input
                                type="date"
                                name="dayofBirth"
                                value={candidateInfo["dayofBirth"] || ''}
                                onChange={handleChange}
                                required
                                autoComplete="new-password"
                                style={{
                                    padding: '8px',
                                    fontSize: '14px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    width: '70%'
                                }} />
                        </div>
                    </div>
                    {/* Bên phải */}
                    <div style={{ width: '45%' }}>
                        {fields.slice(6, 12).map(field => (
                            <div key={field.name} style={{ marginBottom: '12px' }}>
                                <label style={{
                                    fontSize: '15px',
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                    marginBottom: '5px',
                                    display: 'block'
                                }}>
                                    {field.label}
                                </label>
                                <input
                                    type={field.type}
                                    name={field.name}
                                    value={candidateInfo[field.name] || ''}
                                    onChange={handleChange}
                                    required
                                    autoComplete="new-password"
                                    style={{
                                        padding: '8px',
                                        fontSize: '14px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        width: '70%'
                                    }} />
                            </div>
                        ))}
                    </div>
                </div>
                <CustomAlert message={photoError} onClose={() => setPhotoError('')} />
                <CustomAlert message={facebookError} onClose={() => setFacebookError('')} />
            </div>    
            {/* Phần 2: Khả năng nổi bật */}
            <div class="container">
                <img src={skillsImg} alt="Khả năng nổi bật" class="sm-3"style ={{marginTop: '5px',marginBottom: '5px', width: '320px'}}/>
                <div style={{ width: '100%'}}>
                            <label style={{
                        fontSize: '20px',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        display: 'block',  // Đảm bảo label chiếm toàn bộ chiều rộng
                        marginBottom: '13px' // Khoảng cách giữa label và các checkbox
                    }}>
                        Chọn khả năng nổi bật của bạn (có thể chọn nhiều hơn 1 mục)
                    </label>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '45%' }}>
                            {fields.slice(12, 13).map(field => (
                                <div key={field.name}>
                                    <div>
                                        {field.options.slice(0, Math.ceil(field.options.length / 2)).map(option => (
                                            <div key={option} style={{ marginBottom: '5px' }}>
                                                <label style={{ fontSize: '17px', color: '#ffffff'}}>
                                                    <input
                                                        type="checkbox"
                                                        value={option}
                                                        checked={candidateInfo[field.name]?.includes(option) || false}
                                                        onChange={(e) => handleCheckboxChange(e, field.name)}
                                                        style={{
                                                            marginRight: '12px',
                                                            width: '15px',  // Chỉnh kích thước checkbox
                                                            height: '15px'
                                                        }}
                                                    />
                                                    {option}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                            {/* Mục "Khác" */}
                            <div>
                                <input type="text" name={fields[13].name} value={candidateInfo[fields[13].name] || ''} onChange={handleChange} placeholder="Khác (nếu có)" autocomplete="new-password"  style={{ width: '100%', marginTop: '10px' }} />
                            </div>
                        </div>
                        <div style={{ width: '45%' }}>
                            {fields.slice(12, 13).map(field => (
                                <div key={field.name}>
                                    <div>
                                        {field.options.slice(Math.ceil(field.options.length / 2)).map(option => (
                                            <div key={option} style={{ marginBottom: '5px' }}>
                                                <label style={{ fontSize: '17px', color: '#ffffff'}}>
                                                    <input
                                                        type="checkbox"
                                                        value={option}
                                                        checked={candidateInfo[field.name]?.includes(option) || false}
                                                        onChange={(e) => handleCheckboxChange(e, field.name)}
                                                        style={{
                                                            marginRight: '12px',
                                                            width: '15px',  // Chỉnh kích thước checkbox
                                                            height: '15px'
                                                        }}
                                                    />
                                                    {option}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Phần 3: Lĩnh vực hoạt động mong muốn */}
            <div class="container">
                <img src={activityImg} alt="Lĩnh vực hoạt động mong muốn" class="sm-3"style ={{marginTop: '5px',marginBottom: '5px', width: '320px'}}/>
                <div style={{ display: 'flex',  flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px' }}>
                    {fields.slice(14).map(field => (
                        <div key={field.name} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            {field.type === 'checkbox_group' ? (
                                field.options.map(option => (
                                    <div key={option} style={{ flex: '1 1 0%', textAlign: 'left' }}>
                                        <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '17', fontWeight: 'bold', color: '#ffffff' }}>
                                            <input
                                                type="checkbox"
                                                name={field.name}
                                                value={option}
                                                checked={candidateInfo[field.name]?.includes(option) || false}
                                                onChange={(e) => handleCheckboxChange(e, field.name)}
                                                style={{ 
                                                    marginRight: '8px',
                                                    width: '15px',  // Chỉnh kích thước checkbox
                                                    height: '15px'

                                                 }}
                                            />
                                            {option}
                                        </label>
                                    </div>
                                ))
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
            {/* Thông báo lỗi tùy chỉnh */}
            <CustomAlert message={errorMessage} onClose={() => setErrorMessage('')} />
            <div class="d-flex gap-4 mb-3 justify-content-end" style={{marginTop: '10px'}}>
                <button class="btn btn-secondary p-2" style={{borderRadius: '10px',fontWeight: 'bold',color: '#ffffff', backgroundColor: '#a74929'}}type="button" onClick={handleLogout}>Quay lại</button>
                <button class="btn btn-secondary p-2" style={{borderRadius: '10px',fontWeight: 'bold',color: '#ffffff', backgroundColor: '#a74929', marginRight:'10px'}}type="submit">Tiếp tục</button>
            </div>
        </form>
    );
};
export default CandidateInformation;
