import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
function CandidateList() {
    const [candidates, setCandidates] = useState([]);
    useEffect(() => {
        fetch('http://127.0.0.1:8000/api/candidate_details/')
            .then(response => response.json())
            .then(data => setCandidates(data));
    }, []);

    return (
        <div className="container mt-4">
        {/* Căn giữa tiêu đề */}
        <h1 className="text-center">DANH SÁCH THÍ SINH</h1>
        
        {/* Thiết kế bảng với đường kẻ vàcăn giữa nội dung */}
        <table className="table table-bordered text-center mt-4" style={{ width: '70%', margin: '0 auto' }}>
            <thead className="thead-dark">
                <tr>
                    <th>Số thứ tự</th>
                    <th>Họ và tên</th>
                    <th>Mã số sinh viên</th>
                </tr>
            </thead>
            <tbody>
                {candidates.map((candidate, index) => (
                    <tr key={candidate.id}>
                        <td>{index + 1}</td>
                        <td><Link to={`/candidates/${candidate.id}`}>{candidate.name}</Link></td>
                        <td>{candidate.studentId}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
}
export default CandidateList;
