// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginComponent from './LoginComponent';
import QuestionPage from './QuestionPage';
import CandidateInformation from './InformationPage';
import CompletionPage from './CompletionPage';
import ProtectedRoute from './ProtectedRoute';
import CandidateList from './CandidateList';
import CandidateDetail from './CandidateDetail';
const App = () => (
    <Router>
        <Routes>
            <Route path="/candidates/:id" element={<CandidateDetail/>} />
            <Route path="/candidates" element={<CandidateList/>} />
            <Route path="/" element={<LoginComponent/>} />
            <Route element={<ProtectedRoute />}>
                <Route path="/information_page" element={<CandidateInformation/>} />
                <Route path="/question_page" element={<QuestionPage/>} />
                <Route path="/completion" element={<CompletionPage/>} />
            </Route>
        </Routes>
    </Router>
);

export default App;
