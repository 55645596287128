import React from 'react';
import './index.css';

const CustomAlert = ({ message }) => {
    if (!message) return null;

    return (
        <div className="custom-alert">
            <p>{message}</p>
        </div>
    );
};

export default CustomAlert;
