import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import './index.css';
import logoImg from './assets/logo.png';
import titleImg from './assets/titlefinal03.webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginComponent.css';
import Footer from './Footer'; 
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4 to generate unique session IDs

const LoginComponent = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [showNotification] = useState(true); 
    const navigate = useNavigate();

    const handleLogin = async (googleUser) => {
        const id_token = googleUser.getAuthResponse().id_token;
        const profile = googleUser.getBasicProfile();

        try {
            sessionStorage.clear();
            const response = await fetch('https://ctvapi.itbstclub.com/api/google_login_check/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: id_token, email: profile.getEmail() }), // Corrected to use getEmail()
            });

            const data = await response.json();
            if (response.ok) {
                console.log('User authenticated:', data.email);
                sessionStorage.setItem('loggedInEmail', data.email);
                const sessionId = uuidv4();
                navigate(`/information_page?session-id=${sessionId}`);
            } else {
                console.error('Authentication failed:', data.error);
                setErrorMessage(data.error || 'Vui lòng liên kết bằng tài khoản sinh viên');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setErrorMessage('Có lỗi xảy ra. Vui lòng thử lại sau.');
        }
    };

    const handleFailure = (error) => {
        console.error('Google login failed:', error);
        setErrorMessage('Đăng nhập thất bại');
    };

    const onSuccess = (googleUser) => handleLogin(googleUser);
    const onFailure = (error) => handleFailure(error);
    return (
        <div className="login-page">
            {/* Modal thông báo */}
            {showNotification && (
                <div className="notification show" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div className="notification-dialog modal-dialog-centered" style={{justifyContent: 'center'}}>
                        <div className="notification-content" style={{width: '1000px'}}>
                            <div className="notification-header" style={{display: 'block', borderBottom: '1px solid #dee2e6'}}>
                                <h5 className="notification-title" style={{textAlign: 'center'}}>THÔNG BÁO!!!</h5>
                            </div>
                            <div className="notification-body">
                                <p style={{marginTop:'12px', marginBottom: '6px', textAlign: 'center'}}><strong>HIỆN TẠI ĐÃ HẾT HẠN ĐĂNG KÝ</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="login-container">
                <div className="container" style={{ maxWidth: '90px' }}>
                    <picture>
                        <img 
                            src={logoImg} 
                            alt="ITB CLUB" 
                            loading="lazy" 
                            style={{ minWidth: '90%', maxWidth: '100%', marginTop: '15px' }} 
                        />
                    </picture>
                </div>
                <div className="container" style={{ maxWidth: '947px' }}>
                    <div align="center"> 
                        <picture>
                            <img 
                                src={titleImg} 
                                loading="lazy" 
                                alt="Title"
                                style={{ minWidth: '90%', maxWidth: '100%', marginTop: '10px' }} 
                            />
                        </picture>
                    </div>
                    <div className="login-box text-center">
                        <h2 style={{color:'#FFFFFF'}}>Liên kết tài khoản sinh viên</h2>
                        {errorMessage && (
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        <GoogleLogin
                            clientId="1033603321381-p0q6aok61aih77rn7lgapavl89jkcp1j.apps.googleusercontent.com"
                            buttonText="Đăng nhập"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={'single_host_origin'}
                            className="google-button"
                            isSignedIn={false}
                            prompt="select_account" 
                        />
                    </div>
                </div> 
                <Footer />    
            </div>
        </div>   
    );
};

export default LoginComponent;
