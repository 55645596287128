import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'; 
import logoImg from './assets/logo.png';
import itbclubImg from './assets/ITBCLUB.webp'
import './LoginComponent.css';
const CompletionPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const formCompleted = sessionStorage.getItem('formCompleted');
        const loggedInEmail = sessionStorage.getItem('loggedInEmail');

        if (!formCompleted || !loggedInEmail) {
            // Điều hướng về trang login nếu không hợp lệ
            navigate('/');
        }
    }, [navigate]);
    
    return (
        <div class="login-page">
            <div className="login-container">
                <div class="container" style={{ maxWidth: '70px' }}>
                    <picture>
                        <img 
                        src={logoImg} 
                        alt="ITB CLUB" 
                        loading="lazy" 
                        style={{ minWidth: '90%', maxWidth: '100%', marginTop: '25px' }} />
                    </picture>
                </div>
                <div class="container" style={{ maxWidth: '947px' }}>
                    <div align="center"> 
                        <div align="center"> 
                            <picture>
                                <img 
                                src={itbclubImg} 
                                loading="lazy" 
                                alt = "Title"
                                style={{ minWidth: '60%', maxWidth: '70%', marginTop: '10px' }} />
                            </picture>
                        </div>
                        <h3 style={{display: 'block', color: '#ffffff'}}>Đăng ký hoàn tất, bạn có thể đóng cửa sổ này.</h3>
                    </div>
                </div>    
                <Footer/>
            </div>    
        </div>
    );
};

export default CompletionPage;


