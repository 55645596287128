import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logoImg from './assets/logo.png';
import './index.css';
import CustomAlert from './CustomAlert';

const QuestionPage = () => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState(() => {
        const storedAnswers = sessionStorage.getItem('answers');
        return storedAnswers ? JSON.parse(storedAnswers) : {};
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session-id');
    const navigate = useNavigate();

    useEffect(() => {
        const formCompleted = sessionStorage.getItem('formCompleted');
        if (!sessionId || formCompleted) {
            navigate(formCompleted ? '/completion' : '/');
            console.error('Try to reconnect to the previous page');
            return;
        }
    
        fetch('https://ctvapi.itbstclub.com/api/questions/')
            .then(response => response.json()) // Ensure the response is parsed as JSON
            .then(data => {
                console.log('Fetched questions:', data); // Log the fetched data
                if (Array.isArray(data)) { // Ensure data is an array
                    setQuestions(data);
                    const initialAnswers = data.reduce((acc, question) => {
                        acc[question.question_id] = '';
                        return acc;
                    }, {});
                    setAnswers(storedAnswers => storedAnswers ? storedAnswers : initialAnswers);
                } else {
                    throw new Error('Data is not an array');
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setErrorMessage("Lỗi tải câu hỏi. Vui lòng thử lại sau.");
            });
    }, [sessionId, navigate]);
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [name]: value
        }));
    };

    const validateAnswers = () => {
        for (let key in answers) {
            if (!answers[key] || answers[key].trim() === '') {
                return false; // Trả về false nếu câu trả lời trống hoặc chỉ chứa dấu cách
            }
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Validate answers
        if (!validateAnswers()) {
            setErrorMessage("Vui lòng điền đầy đủ câu trả lời và không chỉ chứa dấu cách.");
            setIsSubmitting(false);
            return;
        }
    
        // Set submitting state to true
        setIsSubmitting(true);
    
        // Store answers in sessionStorage
        sessionStorage.setItem('answers', JSON.stringify(answers));
    
        // Retrieve candidate information and email from sessionStorage
        const candidateInfo = JSON.parse(sessionStorage.getItem('candidateInfo'));
        const email = sessionStorage.getItem('loggedInEmail');
    
        // Check if candidateInfo and email are available
        if (!candidateInfo || !email) {
            setErrorMessage("Thông tin ứng viên hoặc email không có sẵn.");
            setIsSubmitting(false);
            return;
        }
    
        // Update candidateInfo with the email
        candidateInfo.candidate_email = email;
    
        // Check if candidate info has already been saved
        if (!localStorage.getItem('candidateInfoSaved')) {
            fetch('https://ctvapi.itbstclub.com/api/save_informations/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(candidateInfo),
            })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(errorData.message || "Lỗi lưu thông tin. Vui lòng tải lại trang để lưu");
                    });
                }
                // Save flag in localStorage
                localStorage.setItem('candidateInfoSaved', 'true');
                console.log("Candidate information saved successfully.");
            })
            .catch(error => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                // Set submitting state to false
                setIsSubmitting(false);
            });
            
        }
        else {
            console.log("Candidate information has already been saved.");
        }
    
        
        const answersData = questions.map(question => ({
            question_id: question.question_id,
            candidate_email: email,
            answer_text: answers[question.question_id]
        }));

        const saveAnswersResponse = await fetch('https://ctvapi.itbstclub.com/api/save_answers/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(answersData),
        });

        if (!saveAnswersResponse.ok) {
            setErrorMessage("Lỗi lưu câu trả lời. Vui lòng tải lại trang để lưu");
            setIsSubmitting(false);
            return;
        }

        console.log('Answers saved successfully');
        sessionStorage.setItem('formCompleted', 'true');
        navigate('/completion');
    };

    const handleBack = () => {
        sessionStorage.setItem('answers', JSON.stringify(answers));
        navigate(`/information_page?session-id=${sessionId}`);
    };

    return (
        <>
            {isSubmitting && (
                <div className="spinner-overlay">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit} className="container-fluid" autoComplete="off">
                <div className="container">
                    <div style={{ padding: '20px', borderRadius: '10px' }}>
                        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <img src={logoImg} alt="Logo" style={{ marginBottom: '10px', width: '50px' }} />
                        </div>
                        {questions.map((question) => (
                            <div key={question.question_id} style={{ marginBottom: '20px' }}>
                                <label style={{ marginRight: '10px', fontSize: '17px', color: '#ffffff' }}>
                                    {question.question_text}
                                </label>
                                <div style={{ marginTop: '10px' }}>
                                    <textarea
                                        name={question.question_id}
                                        value={answers[question.question_id]}
                                        onChange={handleChange}
                                        required
                                        spellCheck="false"
                                        style={{
                                            width: '100%',
                                            height: '190px',
                                            borderRadius: '10px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            fontSize: '17px',
                                            opacity: '0.8',
                                            backgroundColor: '#EDEDED',
                                            resize: 'none',
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <CustomAlert message={errorMessage} onClose={() => setErrorMessage('')} />
                <div className="d-flex gap-4 mb-3 justify-content-end" style={{ marginTop: '10px' }}>
                    <button className="btn btn-secondary p-2" style={{ borderRadius: '10px', fontWeight: 'bold', color: '#ffffff', backgroundColor: '#a74929', marginBottom: '10px' }} type="button" onClick={handleBack}>Quay lại</button>
                    <button className="btn btn-secondary p-2" style={{ borderRadius: '10px', fontWeight: 'bold', color: '#ffffff', backgroundColor: '#a74929', marginBottom: '10px' }} type="submit" disabled={isSubmitting}>Hoàn thành</button>
                </div>
            </form>
        </>
    );
};

export default QuestionPage;
