import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
const Footer = () => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const calculateTimeLeft = () => {
            const deadline = new Date('2024-09-10T10:00:00'); // Đặt thời gian deadline của bạn ở đây
            const now = new Date();
            const difference = deadline - now;

            let timeLeft = '';
            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / 1000 / 60) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                timeLeft = `${days} ngày, ${hours} giờ, ${minutes} phút, ${seconds} giây`;
            } else {
                timeLeft = 'Hết hạn';
            }

            setTimeLeft(timeLeft);
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <footer className="bg-dark" class="container-fluid text-light" style={{ background: 'rgba(0, 0, 0, 0.4)', backdropFilter: 'blur(10x)', padding: '10px 0'}}>
            <div className="d-flex justify-content-around align-items-center" >
                <div className="col-md-4 text-center text-md-left mb-2 mb-md-0" style={{ margin: '10px 0' }}>
                    <p style={{paddingRight: '25px'}}><i class="bi-envelope-fill"></i> Email: itbclub@st.uel.edu.vn</p>
                    <p><i class="bi-telephone-fill"></i> SĐT:  0944772586 (Hoàng Lan) - <br/>
                    0859395478 (Như Ngọc)</p>
                </div>
                <div className="col-md-4 text-center mb-2 mb-md-0" style={{ margin: '10px 0', marginRight: '165px' }}>
                    <p style={{fontWeight: 'bold'}}>HẾT HẠN ĐĂNG KÝ SAU:</p>
                    <p style={{fontWeight: 'bold'}}>{timeLeft}</p>
                </div>
                <div className="col-md-4 text-center text-md-right" style={{ margin: '10px 0' }}>
                    <p><a href="https://www.facebook.com/itbclub.uel" target="_blank" rel="noopener noreferrer" className="text-white">Fanpage</a></p>
                    <p><a href="https://bi.itbstclub.com/" target="_blank" rel="noopener noreferrer" className="text-white" >Website</a></p>
                </div>
            </div>
            <div className="text-center mt-3">
                <p>&copy; Copyright 2024 ITB Club</p>
            </div>
        </footer>
    );
};

export default Footer;
