import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function CandidateDetail() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [candidate, setCandidate] = useState(null);
    const [candidateIndex, setCandidateIndex] = useState(0);
    const [totalCandidates, setTotalCandidates] = useState(0);

    useEffect(() => {
        // Fetch danh sách thí sinh để biết số thứ tự và tổng số thí sinh
        fetch('http://127.0.0.1:8000/api/candidate_details/')
            .then(response => response.json())
            .then(data => {
                setTotalCandidates(data.length);
                const index = data.findIndex(c => c.id.toString() === id);
                setCandidateIndex(index);
            });

        // Fetch API để lấy dữ liệu chi tiết thí sinh
        fetch(`http://127.0.0.1:8000/api/candidate_details/${id}/`)
            .then(response => response.json())
            .then(data => {
                setCandidate(data);
            });
    }, [id, navigate]);

    if (!candidate) return <div>Loading...</div>;

    const handleNext = () => {
        if (candidateIndex < totalCandidates - 1) {
            navigate(`/candidates/${candidateIndex + 2}`); // navigate tới thí sinh tiếp theo
        }
    };

    const handlePrevious = () => {
        if (candidateIndex > 0) {
            navigate(`/candidates/${candidateIndex}`); // navigate tới thí sinh trước đó
        }
    };

    return (
        <div className="container mt-4">
            {/* Nút Home */}
            <button className="btn btn-primary" onClick={() => navigate('/candidates')}>Home</button>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <div style={{ flex: 1, marginRight: '20px', border: '1px solid #000', padding: '20px', maxHeight: '80vh', overflowY: 'scroll' }}>
                    <h2 style={{fontWeight:'bold'}}>Thông tin chi tiết</h2>
                    <p><strong>Họ và tên:</strong> {candidate.name}</p>
                    <p><strong>Mã lớp:</strong> {candidate.classId}</p>
                    <p><strong>Chuyên ngành:</strong> {candidate.major}</p>
                    <p><strong>Giới tính:</strong> {candidate.gender}</p>
                    <p><strong>Ngày sinh:</strong> {candidate.dayofBirth}</p>
                    <p><strong>Quê quán:</strong> {candidate.hometown}</p>
                    <p><strong>Email:</strong> {candidate.email}</p>
                    <p><strong>Link Facebook:</strong> 
                        <a href={candidate.linkFb} target="_blank" rel="noopener noreferrer"> {candidate.linkFb}</a>
                    </p>
                    <p><strong>Địa chỉ:</strong> {candidate.address}</p>
                    <p><strong>Số điện thoại:</strong> {candidate.phone}</p>
                    <p><strong>Ảnh:</strong> 
                        <a href={candidate.photo} target="_blank" rel="noopener noreferrer"> {candidate.photo}
                        </a>
                    </p>
                    <p><strong>Kỹ năng nổi bật:</strong></p>
                    <ul>
                        {candidate.potential && candidate.potential.length > 0 ? (
                            candidate.potential.map((skill, index) => (
                                <li key={index}>{skill}</li>
                            ))
                        ) : (
                            <li>Không có kỹ năng nào được liệt kê.</li>
                        )}
                    </ul>
                    <p><strong>Lĩnh vực muốn hoạt động:</strong></p>
                    <ul>
                        {candidate.department_json && candidate.department_json.length > 0 ? (
                            candidate.department_json.map((department, index) => (
                                <li key={index}>{department}</li>
                            ))
                        ) : (
                            <li>Không có lĩnh vực nào được liệt kê.</li>
                        )}
                    </ul>
                </div>

                <div style={{ flex: 1, border: '1px solid #000', padding: '20px', maxHeight: '90vh', overflowY: 'scroll' }}>
                    <h2 style={{fontWeight:'bold'}}>Câu trả lời</h2>
                    <ul>
                        {candidate.answers && candidate.answers.length > 0 ? (
                            candidate.answers.map((answer, index) => (
                                <li key={index}>
                                    <strong>Câu {index + 1}:</strong> {answer.answer_text}
                                </li>
                            ))
                        ) : (
                            <li>Không có câu trả lời nào được liệt kê.</li>
                        )}
                    </ul>
                </div>
            </div>
            {/* Nút điều hướng */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <button className="btn btn-secondary" onClick={handlePrevious} disabled={candidateIndex === 0}>
                    Quay lại
                </button>
                <button className="btn btn-secondary" onClick={handleNext} disabled={candidateIndex === totalCandidates - 1}>
                    Tiếp theo
                </button>
            </div>
        </div>
    );
}

export default CandidateDetail;
